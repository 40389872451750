import classnames from 'classnames/dedupe';

const Class = {
    /**
     * Init the main Alpine functions and variables
     */
    init() {
        document.addEventListener('alpine:init', () => {
            Alpine.data('ComparisonTable', (add_collapse_button, number_of_rows_collapse, background_color) => ({
                collapsed: false,
                selectedColumn: 0,
                rowsTotalHeight: 0,
                containerTotalHeight: 0,
                dropdownOpen: false,
                dropdownText: '',
                add_collapse_button: add_collapse_button,
                is_active_tab: false,
                clone_top_header: null,
                background_color: background_color,

                init() {
                    this.$nextTick(() => {
                        if (this.add_collapse_button) {
                            // Wait for a short delay to ensure elements are rendered and sizes are accurate
                            setTimeout(() => {
                                this.setRowsTotalHeight(this.number_of_rows_collapse);
                                this.setContainerTotalHeight();
                                console.log(this.rowsTotalHeight, this.containerTotalHeight);
                                this.toggle();
                            }, 0); // Adjust the delay as needed
                        } else {
                            setTimeout(() => {
                                this.setContainerTotalHeight();
                                var value = this.containerTotalHeight + 2;
                                console.log(value);

                                this.$refs.highlight_column.setAttribute('style', 'height:' + value + 'px');
                            }, 0);
                        }

                        // Set the total height of the rows when the window is resized
                        let timeout = null;
                        window.addEventListener('resize', () => {
                            clearTimeout(timeout);
                            timeout = setTimeout(() => {
                                this.setRowsTotalHeight(this.number_of_rows_collapse);
                                this.setContainerTotalHeight();
                                if (this.collapsed) {
                                    this.setContainerCollapsed();
                                } else {
                                    this.setContainerExpaned();
                                }
                            }, 1000);
                        });

                        // If inside a tabs block, handle tab activation
                        this.is_active_tab = this.$data.tab === this.$data.parent_tab;

                        if (this.$data.tab) {
                            this.$watch('tab', (value) => {
                                this.is_active_tab = value === this.$data.parent_tab;
                                this.top_header_position = Class.getCoords(this.$refs.top_header);
                            });
                        }

                        // Activate scroll listener if not mobile
                        if (window.innerWidth > 1024) {
                            this.scrollListener();
                        }
                    });
                },
                toggle() {
                    this.collapsed = !this.collapsed;

                    if (this.collapsed) {
                        this.setContainerCollapsed();
                    } else {
                        this.setContainerExpaned();
                    }
                    this.calculateTopHeaderPosition();
                },
                setContainerTotalHeight() {
                    // Select all row divs inside the wrapper
                    const rows = Array.from(this.$refs.container.querySelectorAll('.row:not(.col-top-header)'));

                    // Calculate the total height by summing up the height of each row
                    this.containerTotalHeight = rows.reduce((totalHeight, row, index) => {
                        const rowHeight = row.offsetHeight; // Get the height of the current row
                        return totalHeight + rowHeight;
                    }, 0);
                },

                setRowsTotalHeight(number_of_rows_collapse = 10) {
                    // Determine the row selector based on screen width
                    const rowSelector = window.innerWidth < 1024 ? '.row:not(.hidden):not(.clone-top-header)' : '.row';

                    // Select the rows within the container, limiting to the specified number
                    const rows = Array.from(this.$refs.container.querySelectorAll(rowSelector)).slice(0, number_of_rows_collapse);

                    // Calculate the total height of the selected rows
                    this.rowsTotalHeight = rows.reduce((totalHeight, row, index) => {
                        const rowHeight = row.offsetHeight; // Get the height of the current row

                        return totalHeight + rowHeight;
                    }, 0);
                },
                setContainerCollapsed() {
                    var value = this.rowsTotalHeight;

                    this.$refs.inner_container.setAttribute('style', 'height:' + value + 'px');
                    if (this.$refs.highlight_column) {
                        this.$refs.highlight_column.setAttribute('style', 'height:' + value + 'px');
                    }
                },
                setContainerExpaned() {
                    var value = this.containerTotalHeight + 2;
                    this.$refs.inner_container.setAttribute('style', 'height:' + value + 'px');
                    if (this.$refs.highlight_column) {
                        this.$refs.highlight_column.setAttribute('style', 'height:' + value + 'px');
                    }
                },

                mobileColumnToggle(key, dropdownText = false) {
                    if (window.innerWidth > 1024) {
                        this.selectedColumn = true;
                        return;
                    }
                    this.dropdownText = dropdownText;
                    this.dropdownOpen = false;
                    this.selectedColumn = key;
                },
                dropdownToggle() {
                    if (this.dropdownOpen) {
                        return this.dropdownClose();
                    }

                    this.$refs.button.focus();

                    this.dropdownOpen = true;
                },
                dropdownClose(focusAfter) {
                    if (!this.dropdownOpen) return;

                    this.dropdownOpen = false;

                    focusAfter && focusAfter.focus();
                },
                scrollListener() {
                    window.addEventListener('scroll', (event) => {
                        // If not the active tab, return
                        if (!this.is_active_tab) {
                            return;
                        }
                        this.calculateTopHeaderPosition();
                    });

                    // Also set the width of the clone header on resize
                    window.addEventListener('resize', (event) => {
                        if (this.clone_top_header) {
                            this.clone_top_header.style.width = this.$refs.top_header.offsetWidth + 'px';
                        }
                    });
                },
                calculateTopHeaderPosition() {
                    // Get current scroll position
                    var scroll = window.scrollY;

                    // Get the position of the top header
                    this.top_header_position = Class.getCoords(this.$refs.inner_container);
                    this.top_header_position = document.body.classList.contains('logged-in')
                        ? { top: this.top_header_position.top - 40, left: this.top_header_position.left }
                        : this.top_header_position;

                    var fixed_top = document.body.classList.contains('logged-in') ? 140 + 'px' : 100 + 'px';

                    // If the scroll is greater than the top header position, clone the top header
                    if (scroll >= this.top_header_position.top - 100) {
                        if (!document.querySelector('.clone-top-header')) {
                            this.clone_top_header = this.$refs.top_header.cloneNode(true);

                            this.clone_top_header.className = classnames('clone-top-header overflow-hidden bg-' + this.background_color, this.clone_top_header.className);
                            this.clone_top_header.style.position = 'absolute';
                            this.clone_top_header.style.top = fixed_top;
                            this.clone_top_header.style.width = this.$refs.top_header.offsetWidth + 'px';
                            this.clone_top_header.style.zIndex = '40';

                            this.clone_top_header.setAttribute('x-ref', 'clone_top_header');
                            if (this.$refs.highlight_column) {
                                this.clone_top_header.querySelector('.highlight-column').setAttribute('x-ref', 'clone_highlight_column');
                            }

                            this.$refs.inner_container.querySelector('.wrapper').insertBefore(this.clone_top_header, this.$refs.top_header);

                            setTimeout(() => {
                                this.clone_top_header.className = classnames({ scrolling: true }, this.clone_top_header.className);
                            }, 200);
                        }
                    } else {
                        if (document.querySelector('.clone-top-header')) {
                            document.querySelector('.clone-top-header').remove();
                        }
                    }

                    // If the scroll is greater than the top header position, set the clone header to absolute
                    var current_container_height = this.collapsed ? this.rowsTotalHeight + 1 : this.$refs.container.scrollHeight;
                    if (scroll >= this.top_header_position.top - 100 - this.$refs.top_header.offsetHeight + current_container_height) {
                        if (this.clone_top_header) {
                            this.clone_top_header.style.position = 'absolute';
                            var border_offeset = 1;
                            var top = current_container_height - this.$refs.top_header.offsetHeight - border_offeset;
                            this.clone_top_header.style.setProperty('top', top + 'px', 'important');
                            this.clone_top_header.style.borderRadius = '16px';
                        }
                    } else {
                        if (this.clone_top_header) {
                            this.clone_top_header.style.position = 'fixed';
                            this.clone_top_header.style.top = fixed_top;
                            this.clone_top_header.style.borderRadius = '0px';
                        }
                    }
                },
            }));
        });
    },
    getCoords(elem) {
        // crossbrowser version
        var box = elem.getBoundingClientRect();

        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left) };
    },
};

export default Class;
